.container_contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.firstContentContact {
    margin-top: 150px;
    color: #e9d6cd;
    font-size: 25px;
}

.bug {
    color: #e9d6cd;
    margin-top: 50px;
    text-align: center;
    font-style: italic;
}

.bug a {
    padding-top: 50px;
    color: #e9d6cd;
}

.content_contact {
    color: #e9d6cd;
    margin-top: 50px;
}

.content_contact a {
    color: #e9d6cd;
}

.content_contact > div {
    margin-top: 30px;
    padding: 20px 0;
    border-bottom: 1px #e9d6cd solid;
}

.content_contact h2 {
    margin-bottom: 20px;
}

.content_contact p {
    margin-top: 20px;
}

@media screen and (max-width: 850px) {

}