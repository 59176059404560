.contentLogin {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contentLogin > div {
    max-width: 500px;
    display: flex;
    flex-direction: column;
}

.isAdmin {
    font-size: 25px;
    color: #e9d6cd;
    display: flex;
}

.isAdmin label {
    /*margin-left: 20px;*/
}

.isAdmin input {
    /*appearance: none;*/
    /*background: none;*/
    /*margin: 0;*/
    /*font: inherit;*/
    /*color: currentColor;*/
    /*width: 1.15em;*/
    /*height: 1.15em;*/
    /*border: 1px solid #e4c4a3;*/
    /*transform: translateY(-0.075em);*/
    /*display: grid;*/
    /*place-content: center;*/
    /*position: relative;*/
}

.isAdmin input[type = "checkbox"]::before {
    /*content: "";*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%) scale(0);*/
    /*position: absolute;*/
    /*width: 17px;*/
    /*height: 17px;*/
    /*transition: 120ms transform ease-in-out;*/
    /*box-shadow: inset 1em 1em #e4c4a3;*/
}

.isAdmin input[type="checkbox"]:checked::before {
    /*transform: translate(-50%, -50%) scale(1);*/
}



.errorText {
    font-size: 20px;
    font-style: italic;
    margin-bottom: 20px;
    color: #c00a0a;
}