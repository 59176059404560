.container_docs {
    color: #e9d6cd;
}

.container_docs p {
    font-size: 25px;
    text-align: center;
}

.list_item {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
}

.item a {
    border-radius: 20px;
    background-color: #0c0501;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    text-decoration: none;
    color: #e9d6cd;
    margin: 20px;
    transition: all ease-in-out .2s;
}

.item a:hover {
    -webkit-box-shadow: 5px 6px 23px 3px #442a11;
    box-shadow: 5px 6px 23px 3px #442a11;
}

.item a h1 {
    margin-top: 40px;
}

.item img {
    width: 150px;
}

.list_pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.list_pdf iframe {
    margin-bottom: 80px;
}

.navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.navigation a {
    color: #e9d6cd;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: #0c0501;
    padding: 20px 40px;
    border-radius: 5px;
}

.navigation a:hover {
    -webkit-box-shadow: 5px 6px 23px 3px #442a11;
    box-shadow: 5px 6px 23px 3px #442a11;
}

.navigation img {
    width: 40px;
}

.navigation h2 {
    margin: 0 20px;
}

.navigation svg {
    font-size: 40px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 100px 0;
}

.icon img {
    width: 150px;
}

@media screen and (max-width: 1042px) {
    .navigation {
        flex-direction: column;
    }

    .navigation a {
        margin-top: 20px;
    }
}