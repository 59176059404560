.top_acceuil {
    width: 100%;
    height: 80%;
    background: url("../../img/troupe_img.png") no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 -59px 58px -8px #191009;
}

.top_acceuil h1 {
    color: rgba(255, 255, 255, 0.78);
    font-size: 80px;
    font-weight: 1000;
    text-align: center;
    transition: all .5s linear;
}

.container_accueil {
    /*height: 100vh;*/
}

.top_acceuil {
    height: 90vh;
}

.second_acceuil {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.img_baden {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img_baden p {
    color: #bc795d !important;
    text-align: center;
}

.second_acceuil div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.second_acceuil p {
    width: 500px;
    color: #e9d6cd;
    font-size: 25px;
    font-style: italic;
}

.quote {
    font-size: 100px !important;
    color: #904109 !important;
    position: absolute;
}

.quote_top {
    top: -60px;
    left: -40px;
}

.quote_bot {
    right: -300px;
    bottom: -90px;
}

.imageBaden {
    width: 240px;
    height: 342px;
    background: url("../../img/badenPowel.jpg") center center;
    background-size: cover;
    box-shadow: inset 0 0 10px 20px #191009;
}


.troisieme_acceuil {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.troisieme_acceuil > p {
    width: 80%;
    color: #e9d6cd;
    font-size: 25px;
    margin: 40px;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .top_acceuil h1 {
        font-size: 50px;
    }
}

@media screen and (max-width: 800px) {
    .second_acceuil {
        flex-direction: column;
    }

    .second_acceuil .imageBaden {
        margin-top: 50px;
    }

    .top_acceuil h1 {
        font-size: 40px;
    }

    .second_acceuil p {
        width: 80%;
        text-align: center;
    }

    .citation {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .quote {
        position: relative;
    }

    .quote_top {
        top: 50px;
        left: 0;
    }

    .quote_bot {
        right: 0;
        bottom: 0;
    }

    .troisieme_acceuil > div {
        width: 90%;
    }
}

@media screen and (max-width: 500px) {

    .top_acceuil h1 {
        font-size: 30px;
    }
}