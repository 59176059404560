@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'templier';
    src: url('fonts/BLKCHCRY.TTF');
}

* {
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;;
    padding: 0;
    margin: 0;
}

body {
    background-color: #191009;
}

.inputCustom {
    margin: 20px 0;
    display: flex;
    border: 1px solid #e4c4a3;
    align-items: center;
    padding: 5px 0;

}

.inputCustom input {
    outline: none;
    background: none;
    border: none;
    color: #e9d6cd;
    font-size: 20px;
    max-width: 500px;
}

.inputCustom svg {
    font-size: 25px;
    color: #e4c4a3;
    margin: 10px 10px;
}

.buttonCustom {
    border: none;
    background-color: #e9d6cd;
    font-size: 25px;
    padding: 10px 50px;
    cursor: pointer;
    transition: 120ms all ease-in-out;
}

.buttonCustom:hover {
    background-color: #e4c4a3;
}