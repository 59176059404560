.navbar {
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    top: 0;
    transition: all .2s linear;
    overflow-x: scroll;
}

.navbar > div {
    margin: 20px;
}

.navbar .leftContentNav {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar img {
    width: 80px;
}

.navbar .iconNav {
    margin-left: 20px;
    cursor: pointer;
}

.hiddenNavBar {
    width: 100%;
    padding: 10px 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.hiddenNavBar > div {
    margin-left: 40px;
}

.hiddenNavBar img {
    margin-bottom: 20px;
}

.navShadow {
    background-color: #191009;
    box-shadow: 0 -5px 30px 10px rgba(144, 65, 9, 0.2);
}

.hiddenNavBar a {
    color: #fff;
    text-decoration: none;
    font-size: 25px;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.principalNav .icon_nav {
    margin-right: 10px;
}

.principalNav {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: bold;
}

.sub_menu {
    margin-left: 40px;
}



/*@media screen and (max-width: 1610px) {*/

/*    .closeNav {*/
/*        display: none;*/
/*        position: absolute;*/
/*        top: 10px;*/
/*        right: 10px;*/
/*        color: #e9d6cd;*/
/*        font-size: 35px;*/
/*        z-index: 100;*/
/*        cursor: pointer;*/
/*        padding: 10px;*/
/*        background-color: #332219;*/
/*        justify-content: center;*/
/*        align-items: center;*/
/*        border-radius: 50%;*/
/*    }*/

/*    .openNav {*/
/*        display: none;*/
/*        top: 10px;*/
/*        right: 10px;*/
/*        color: #e9d6cd;*/
/*        font-size: 35px;*/
/*        z-index: 100;*/
/*        cursor: pointer;*/
/*        padding: 10px;*/
/*        justify-content: center;*/
/*        align-items: center;*/
/*        border-radius: 50%;*/
/*    }*/

/*    .openNav {*/
/*        display: none;*/
/*    }*/

/*    .navbar_open {*/
/*        width: 100%;*/
/*        height: 100%;*/
/*        position: fixed;*/
/*        top: 0;*/
/*        left: 0;*/
/*        background-color: #26190e;*/
/*        flex-direction: column;*/
/*        justify-content: start;*/
/*    }*/

/*    .hiddenNavBar {*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        align-items: start;*/
/*        flex-direction: column;*/
/*    }*/

/*    .leftContentNav {*/
/*        flex-direction: column;*/
/*    }*/

/*    .content_item_nav {*/
/*        margin-top: 15px;*/
/*    }*/

/*    .closeNav {*/
/*        display: flex;*/
/*    }*/
/*}*/
