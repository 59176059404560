.separator {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
}

.sep_content {
    width: 100px;
    height: 2px;
    background-color: #904109;
}