.intro {
    margin-top: 150px;
}

.titrePrimaire {
    color: #e9d6cd;
    font-size: 40px;
    margin-bottom: 100px;
    font-weight: 900;
    text-align: center;
}

.secondaryTitre {
    color: #e9d6cd;
    font-size: 30px;
    margin-bottom: 100px;
    font-weight: 800;
    text-align: center;
}

.intro > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.intro a {
    color: #26190e;
    background-color: #e9d6cd;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 25px;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.intro a:hover {
    background-color: #e4c4a3;
    text-decoration: underline;
}

.containerTroupe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.presentationChef {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.personne {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.personne .poste {
    color: #191009;
    background-color: #e9d6cd;
    font-size: 25px;
    font-weight: bold;
    margin: 20px 0;
    padding: 10px 20px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.personne .nom {
    color: #e9d6cd;
    font-size: 30px;
}

.personne img {
    width: 400px;
    height: 400px;
    position: relative;
    z-index: -2;

}

.personne .image {
    overflow: hidden;
    width: 400px;
    /*box-shadow: inset 0 0 10px 20px #191009;*/
    img {
        border-radius: 10px;
        border: 2px dashed #e9d6cd;
    }
}

.containerTroupe h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 40px;
    color: #904109;
}

.secondeContent {
    padding-top: 150px;
}

.secondeContent > p {
    text-align: center;
}

.criTroupe {
    margin-top: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #e9d6cd;
}

.trad {
    color: #bc795d;
    font-style: italic;
    margin-top: 20px;
    margin-bottom: 50px;
}

/* ==== CALENDRIER ====*/
.firstContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
    padding-top: 150px;
}

.calendrier {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    border: 1px solid #904109;
    border-radius: 5px;
    padding: 10px;
    margin-top: 30px;
    transition: .2s all ease-in-out;
}

.cal_1_ligne {
    transform: translateY(-35px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #e9d6cd;
}

.cal_1_ligne .time {
    background-color: #191009;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    padding: 0 15px;
}

.cal_1_ligne .time svg {
    font-size: 30px;
    margin-right: 10px;
}

.cal_1_ligne .time p {
    font-size: 25px;
}

.type {
    font-size: 25px;
    background: #904109;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: bold;
}

.cal_2_ligne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #e9d6cd;
}

.cal_2_ligne > div {
    margin: 0 20px;
}

.cal_2_ligne p {
    font-size: 35px;
}

.cal_2_ligne svg {
    font-size: 30px;
}

.cal_3_ligne {
    margin-top: 20px;
}

.cal_3_ligne span {
    text-decoration: underline;
    background: #e9d6cd;
    color: #191009;
    padding: 5px 10px;
    border-radius: 5px;
    transition: .2s all ease-in-out;
}

.cal_3_ligne span:hover {
    background-color: #904109;
    color: #e9d6cd;
}

.cal_3_ligne p {
    color: #e9d6cd;
    font-size: 25px;
    line-height: 50px;
}

/* ==== Patrouilles ==== */
.thridContent {
    padding-top: 150px;
}

.titre {
    text-align: center;
    margin-bottom: 20px;
    font-size: 40px;
    color: #904109;
}

.allPat {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.bande {
    margin-top: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}

.bande > div {
    width: 50%;
    height: 100%;
}

.patrouille {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    margin: 50px 50px;
}

.patrouille .img_partrouille {
    width: 210px;
    box-shadow: inset 0 0 10px 5px #191009;
}

.patrouille .img_partrouille img {
    width: 210px;
    height: 210px;
    position: relative;
    z-index: -2;
}

.patrouille .nom_patrouille {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.icon_eye {
    color: #e9d6cd;
    font-size: 35px;
    margin-left: 20px;
    cursor: pointer;
}

.patrouille .nom_patrouille h1 {
    color: #e9d6cd;
    margin-top: 20px;
}

.patrouille .patron h1 {
    color: #e9d6cd;
    font-size: 20px;
}

.patrouille .desc p {
    color: #e9d6cd;
    font-size: 25px;
    text-align: center;
}

.patrouille .patrouillards {
    color: #e9d6cd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.patrouillards > div {
    margin: 20px 0;
}

.patrouillards .cp {
    background-color: #904109;
    padding: 5px 10px;
    border-radius: 10px;
}

.cri {
    color: #e4c4a3;
    font-style: italic;
    text-align: center;
    margin-top: 20px;
}

.chant {
    width: 100%;
    position: relative;
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
}

.refrain {
    font-weight: bold;
    font-style: italic !important;
}

.chant p, .refrain {
    width: 70%;
}

.chant {
    font-style: normal;
    color: #e9d6cd;
    font-size: 25px;
    text-align: center;
}

@media screen and (max-width: 1300px) {
    .presentationChef {
        grid-template-columns: repeat(2, 1fr);
    }

    .allPat {
        grid-template-columns: repeat(1, 1fr);
    }

    .calendrier {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .intro > div {
        flex-direction: column;
    }

    .tetxIn {
        flex-direction: column;
    }

    .tetxIn > div {
        margin-left: 0;
        margin-top: 20px;
    }
}

@media screen and (max-width: 850px) {
    .cal_1_ligne {
        flex-direction: column;
    }

    .cal_1_ligne .type {
        margin-top: 10px;
    }

    .cal_3_ligne p {
        font-size: 20px;
    }

    .presentationChef {
        grid-template-columns: repeat(1, 1fr);
    }

    .chant, .refrain {
        font-size: 20px;
    }
}

.tetxIn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.connect {
    padding: 10px 30px;
    border: 1px solid #904109;
    background-color: #904109;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    margin-left: 30px;
    border-radius: 50px;
}

.tetxIn input {
    padding: 10px 10px;
    font-size: 20px;
    outline: none;
    border-radius: 5px;
    /*border: 3px solid ;*/
    border: none;
    background-color: #e9d6cd;
}

.content_access_photo {
    margin-top: 50px;
}

.content_access_photo a {
    color: #e9d6cd;
    font-size: 25px;
    text-align: center;
    margin-top: 20px;
}

.content_access_photo p {
    text-align: center;
    margin-top: 20px;
}

.content_access_photo .enter {
    color: #e4c4a3;
    font-style: italic;
}

.content_access_photo .incorrect {
    color: rgba(255, 48, 9, 0.76)
}

.icon_eye {
    padding-top: 5px;
}

.video {
    width: 100%;
    margin-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.video iframe {
    width: 900px;
    height: 600px;
}

.video h1 {
    margin-bottom: 50px;
}


@media screen and (max-width: 930px) {
    .video iframe {
        width: 500px;
        height: 300px;
    }
}

@media screen and (max-width: 530px) {
    .video iframe {
        width: 90%;
        height: 300px;
    }
}