.container_footer {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #26190e;
    flex-direction: column;
    color: #e9d6cd;
    font-size: 25px;
    padding: 20px 0;
    text-align: center;
    transition: all .2s linear;
}

.container_footer img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-top: 10px;
}

@media screen and (max-width: 850px) {
    .container_footer {
        font-size: 15px;
    }

    .container_footer img {
        width: 30px;
        height: 30px;
    }
}