h4 {
  font-size: 20px;
  font-weight: normal;
  color: #ef3434;
  font-style: italic;
  text-transform: uppercase;
}

.content_imaginaire {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.imaginaire {
  transition: all .2s ease-in-out;
  position: relative;
  color: #D3D3D3;
  padding: 0 50px;
  width: 70%;

  p {
    font-size: 20px;
    text-align: justify;
  }

  h1 {
    margin-top: 100px;
    font-size: 50px;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 38px;
    margin-bottom: 20px;
    font-weight: bold;
    margin-top: 50px;
  }

  table {
    border-collapse: collapse;

    td, th {
      padding: 10px 0;
      border-bottom: 1px solid #D3D3D3;
    }

    th {
      width: 220px;
    }

    margin-bottom: 100px;
  }
}

.imaginaire h1, .imaginaire h2 {
  font-family: 'templier', serif;
}

.content_text_img {
  display: flex;
  justify-content: center;
  align-items: start;

  img {
    width: 300px;
    border-radius: 10px;
    border: 2px #ffffff dashed;
  }

  p {
    margin: 0 20px;
  }
}


.arbre {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ligne_arbre {
    display: flex;
    justify-content: center;
    align-items: start;
    margin: 30px 0;

    .top {
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 30px;
        background: #D3D3D3;
        bottom: -32px;
      }
    }

    .bot {
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 30px;
        background: #D3D3D3;
        top: -32px;
      }
    }
  }

  .sep {
    width: 783px;
    height: 2px;
    background: #D3D3D3;
    margin: 0;
  }

}

.item_personne_arbre {
  margin: 0 10px;
  width: 240px;
  transition: all .2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    object-position: center;
  }

  p {
    text-align: center;
    width: 100%;
    color: #191009;
    background-color: #D3D3D3;
  }

  h3 {
    background: #5b5b5b;
    padding: 10px 0;
    width: 100%;
    text-align: center;
  }
}

.customArbre {
  .sep {
    width: 1043px;
  }
}


@media screen and (max-width: 1050px) {
  .imaginaire {
    width: 100%;
  }

  .arbre {
    overflow-x: scroll;
  }

  .arbre .ligne_arbre .item_personne_arbre img {
    width: 100px;
  }

  .arbre .ligne_arbre .item_personne_arbre {
    width: 140px;
  }

  .sep {
    width: 483px !important;
  }
}

@media screen and (max-width: 750px) {
  .content_text_img {
    flex-direction: column;
    align-items: center;
  }
}