.content_histoire {
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
}

.hist {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    div {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        .left {
            width: 3px;
            height: 100px;
            background-color: #e4c4a3;
            position: relative;
            &:before {
                content: "";
                width: 15px;
                height: 15px;
                position: absolute;
                background-color: #904109;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                border: 2px solid #e4c4a3;
                transform: translate(-50%, -50%);
            }
        }

        .right {
            margin-left: 50px;
            display: flex;
            justify-content: start;
            align-items: start;
            flex-direction: column;
            border: 1px solid #e4c4a3;
            padding: 10px 30px;
            border-radius: 5px;

            h3, h2 {
                color: #fff;
            }
        }
    }
}