.container_generateur {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #e9d6cd;

  .content_image_tab {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }

  .image_content {
    div {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;

      h3 {
        margin: 0 40px;
      }
    }
  }
}